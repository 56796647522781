.help-window {
    position: fixed;
    background-color: #333;
    color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* resize: both; */
    user-select: none;
}

.help-header {
    background-color: #444;
    padding: 10px;
    /* cursor: move; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.help-header h4 {
    margin: 0;
}

.close-button {
    cursor: pointer;
    font-size: 18px;
}

.help-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.media-container {
    flex: none;
    text-align: center;
    margin: 10px 0;
}

.media-content {
    max-width: 100%;
    max-height: 100%;
}

.divider-line {
    border: 0;
    height: 1px;
    background: #666;
    margin: 10px 0;
}

.text-content {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
}

.help-footer {
    background-color: #444;
    padding: 5px;
    display: flex;
    justify-content: space-between;
}

.slider-button {
    background-color: #555;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
}

.slider-button:hover {
    background-color: #666;
}

.toggle-help-button {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 10px;
    background-color: #555;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    z-index: 1001;
}

.toggle-help-button:hover {
    background-color: #666;
}

/* .resize-handle {
    position: absolute;
    background-color: transparent;
    z-index: 1001;
}

.resize-handle-right {
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
    cursor: ew-resize;
}

.resize-handle-bottom {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    cursor: ns-resize;
}

.resize-handle-corner {
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    cursor: nwse-resize;
} */

.language-icon {
    width: 32px;
    height: auto;
    cursor: pointer;
    margin-left: 10px;
    object-fit: contain;
}
